.ini_text {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bolder;
    font-style: italic;
    text-underline-offset: 3px;
}
.bg {
    background-color: var(--form-input-bg);
    color: var(--wh-text);
    outline: none;
    border: none; 
    
}
.rounded_btn {
    border-radius: 30px;
}
.under_line {
    height: 1px;
    width: 90%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 1.08%, rgba(255, 255, 255, 0.3) 52.9%, rgba(255, 255, 255, 0) 100%);
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}