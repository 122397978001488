
.screen_seven {
    margin-top: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    position:relative;
}
.p_seven_text_large {  
    margin-top: 5vh; 
    color: '#FFFFFF';
    font-weight: 700;   
    font-size: var(--txt-bold);   
    line-height: var(--txt-bold-gap); 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.p_seven_text_normal {
    margin-top: 1.5vw;   
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
    text-align: center;
}
.text_normal_2 {
    margin-top: 0;
}
.p_seven_form_container {
    height: 60vh;
    width: 55vw;
    padding-top: 3vh;
}

.p7_img_car {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20vw;
}
.bottom_portion {
    position: absolute;
    bottom: 20px;
    left: 37vw;
}
.footer_note_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}


.remark_sign1 {    
     display: flex;
     align-items:center;
     justify-content: flex-end;
     width: 20%;
}
.remark_sign2 {   
    display: flex;
    align-items:center;
    justify-content: flex-start;
    width: 20%;
}
.page_bottom_img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20vw;
}



@media screen and (max-width: 950px) {
    .p_seven_form_container {       
        width: 75vw;      
    }
}
@media screen and (max-width: 680px) {
    .p_seven_form_container {       
        width: 92vw;      
    }   
}
@media screen and (max-width: 540px) {
    .page_bottom_img {
        display: none;
    }
}

@media screen and (max-width: 380px) {
    
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
        margin-top: 30px;
    }
} 

.lower_ballast {
    height: 20px;
    width: 100%;  
}
