@keyframes slide_in_fm_left {
    0% {opacity: 0;
        transform: translateX(-100%);
    } 
    90% {
        opacity: 0.7;
        transform: translateX(25%);
    }  
    95% {
        opacity: 0.7;
        transform: translateX(-5%);
    }  
    100% {opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slide_in_fm_down {
    0% {opacity: 0;
        transform: translateY(400%);
    } 
    90% {
        opacity: 0.7;
        transform: translateY(-15%);
    } 
    95% {
        opacity: 0.7;
        transform: translateY(5%);
    }  
    100% {opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes moves_up_down {
    0% {
        transform: translateX(0%);
    } 
    50% {
        
        transform: translateX(1%);
    } 
   
    100% {
        transform: translateX(0%);
    }
}


.sticky_btn {
    position: fixed;
    bottom: 40px;    
    right: 40px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 100%;
    z-index: 10000;
    transition: all 1.8s ease-in ease-out 2s; 
    animation: slide_in_fm_down 2s;
} 
 
/* .sticky_btn :hover {
  
    box-shadow: 25px -1px 8px #00A9E9, -25px 10px 8px #00A9E9, 25px 5px 8px #00A9E9; 
    border-radius: 100%;
}  */


.sticky_phone_btn {
    position: fixed;
    bottom: 45%;    
    left: 20px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 50%;
    z-index: 10000;
    transition: all 1.8s ease-in ease-out 2s; 
    animation: slide_in_fm_left 1s;
    /* animation: moves_up_down 1s infinite; */
}

@media (max-width: 500px) {
    .sticky_btn {        
        bottom: 20px;    
        right: 20px;        
    }
}