.adaptiv_text_one {
    font-size: calc(10px + 6 * (100vw / 1280));
  }
  @media (max-width: 767px) {
   
    .adaptiv_text_one {
      font-size: calc(10px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
    }
  }
.screen {
    background-color: var(--main-bg);  
}

.page_wrapper {
    height: 97%;
  
}
.half_h {
    height: 50%;    
}
.full_h {
    height: 100vh;   
}
.center_items {
    display: flex;
    align-items:center;
    justify-content: center;
}

.card_container {
  
  display: flex;
  flex-direction: row;
  align-items: left;
   
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;   
}

.shade_bg {
    background-color: var(--dark-shade-bg);
    padding: 5px;
    border-radius: var(--border-radius);
    color: var(--wh-txt-clr);
}
.shade_bg h1, h2, h3, h4, h5, h6 {  
    color: var(--wh-text);   
}


@media screen and (max-width: 750px) {
    
    .center_items {       
        align-items: flex-end;
        margin-bottom: 30px;       
    }
}
@media screen and (max-width: 590px) {
    
    .center_items {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
}
@media screen and (max-width: 300px) {
   
}
.text_container {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
}
.darker_text {
    color: var(--darker-text);;
    font-weight:700;
    font-size: larger;
    background-color:var(--light-bg);
    padding: 5px;
    border-radius: 10px;
    max-width: 70%;
    text-align: left;
}






.modal_background {
    background-color: rgba(45, 102, 148, 0.849);
}

.header {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-shadow: none;
    color:rgb(255, 255, 255);
    font-weight: bold;
    font-size: large;
   
}
.header h3 {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-shadow: none;
    color:rgb(255, 255, 255);
    font-weight: bold;
    font-size: large;
   
}

.ride_detail__img {
    width: 22rem;
}

.link_style__white :input-group-text {
    color: rgb(255, 255, 255);
    transform: scale(1.5,1.5);
    text-shadow: rgb(95, 91, 87) 1px 1px;
    font-size: medium;
    background-color:rgba(45, 102, 148, 0.849) ;
}
.car_button_agreement {
    width: 420px;
    padding-right: 12px;
}
.car_button_container {
    margin-top: 3rem;
}
.img_grid {
    max-width: 500px;
    margin: auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;   
}
.img_grid_privat {
    max-width: 500px;
    margin: auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;   
}
.white_line {
    color: white;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    height: 3px;
    
    background-color: #fefeff;
}
@media screen and (max-width: 900px) {
    .car_button_container {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .car_button_agreement {
        width: inherit;
    }
    .img_grid {
        
        grid-template-columns: 1fr;
    }
  }

  .car_link {
    margin-top: 3rem;
    cursor: pointer;
    width: 110px;
    height: 100px;
    margin: 30px 20px;
}
.car_link:hover {  
    cursor: pointer;
    background-color:rgba(76, 119, 156, 0.192);
    border-radius: 40px 40px 20px 20px;
    transform: scale(1.1);
    color: blue;
}
.car_link_pic {
    width: 100px;
    height: 100px;
}



.cards_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px; 
}
.car_card_container {
    position: relative;
    justify-self: center;
    width: 350px;
    height: 150px;
    background-color:rgba(0, 255, 255, 0.233);
    border-radius:20px;
    display: flex;
    flex-direction: row;
    z-index: 1;   
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.car_card_container:hover {
    background-color:rgba(0, 255, 255, 0.555);
    
    transform:  translate3d(10px, 10px, 50px) scale(1.1) ;
    /* transform: perspective(100px) rotateX(-5deg); */
    transition: all 0.8s ease-in 0s;
    cursor: pointer;
    z-index: 1;
    
}
.car_img_container {
    position: relative;
    /* background-color:rgba(185, 134, 185, 0.507); */
    height: 100%;
    width: 40%;
    z-index: 1;
}
.car_name_container {
    /* background-color:rgba(134, 185, 185, 0.507); */
    height: 100%;
    width: 60%;
    z-index: 1;
}
.car_img {
   
    width: 120px;
    height: 120px;
    border-radius:50%; 
    position: absolute;
    top:10%;
    left:10px;
    z-index: 1;
    filter: drop-shadow(10px 10px 4px #11325d62);
    /* filter: drop-shadow(0 0 0.75rem rgb(5, 83, 114)); */
}
.car_plateNum {
    height: 50%;
    padding-top: 25px;
    margin-bottom: 10px;
    z-index: 1;
    text-shadow: 2px 2px #11325d62;
}
.car_plateNum_h6 {
    text-shadow: 2px 2px #11325d62;
}
.card_overlay {
    width: 100%;
    height: 100%;
    position:absolute;
    background-color:rgb(60, 66, 71);
    border-radius:20px;
    padding-top: 41px;
    z-index: -1;
    display: none;
}
.card_overlay:hover {
    z-index: -10;
}


@media screen and (max-width: 900px) {
    .cards_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px; 
    }
    
    .car_card_container {
        position: relative;
        width: 96%;
        height: 150px;       
    }
    .car_img {   
        width: 80%;
        height: 80%;
        border-radius:50%; 
        position: absolute;
        top:15px;
        left:10px;
        
    }
}

@media screen and (min-width: 1661px) {
    .cards_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 80px; 
    }
    
   
}


    
            /* TRANSPARENT TEXT */

/*              
            .black_box {
                height: 380px;
                width: 1100px;
                background: url(../pic/bg3.jpg)  -20px -20px no-repeat;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;

            display:block;
            font-size: 30px;
            font-family: arial;
            text-align: center;
            margin-top: -20px;
            font-weight: 900;
            letter-spacing: -25px;
            background-size: cover;

            writing-mode: vertical-rl;
            text-orientation: mixed;

            } 
             */

             /* vertical text */
            /*              
             .outer{
                writing-mode: tb-rl;
                display:flex;
                align-items:center;
                justify-content: flex-end;
              }
              .inner{
                transform: rotate(180deg);
                transition: transform 1s ease-in-out;
              }
               */

               /* Button with shadow */
               /* .button {
                width: 140px;
                height: 45px;
                font-family: 'Roboto', sans-serif;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 2.5px;
                font-weight: 500;
                color: #000;
                background-color: #fff;
                border: none;
                border-radius: 45px;
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease 0s;
                cursor: pointer;
                outline: none;
                }
              
              .button:hover {
                background-color: #2EE59D;
                box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
                color: #fff;
                transform: translateY(-7px);
              } */

              /* Fixed BUTTON 1 */
              /* .box {
                width: 500px;
                height: 300px;
                position: relative;
                background-color: cornflowerblue;
              }              
              
              button {
                position: absolute;
                bottom: 0.5rem;
                right: 0.5rem;
              } */

               /* Fixed BUTTON 2 */
              /* .feedback {
                background-color : #31B0D5;
                color: white;
                padding: 10px 20px;
                border-radius: 4px;
                border-color: #46b8da;
              }
              
              #mybutton {
                position: fixed;
                bottom: 4px;
                right: 10px;
              } */