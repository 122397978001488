.card {
    margin: 20px;
    background: #061928b5;
    /* border: 1px solid var(--border-color); */
    border-radius: var(--border-radius);
    width: 350px;
    --padding: 1rem;
    text-shadow: 1px 1px 2px black;
    margin-right: 1rem;
    margin-bottom: 0.9rem;
    backdrop-filter: blur(15px);
}


.card_header {
 font-size: 1.5rem;
 padding-bottom: 0;
 margin-bottom: 0.5rem;
 overflow: hidden;
}

.card_img {
 overflow: hidden;
 padding: 0;
}

.card_img_item {
    display: block;
    width: 100%;
    max-height: 250px;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
    transition: 200ms transform ease-in-out;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    
}
.card_img_item:hover {
    transform: scale(1.025);
    overflow: hidden;
}
.card:hover > .card_header.card_img > img {
    transform: scale(1.02);
    overflow: hidden;
}
.card_body {
 font-size: 1rem;
 text-align: left;
 padding: 0 var(--padding);
 font-weight: 300;
 font-size: var(--txt_nomal);
 line-height: 25px;
 text-align: left;
}

.card_footer {
 margin-top: 1rem;
 padding: var(--padding);
 padding-top: 0;
}
.break_word {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
   word-wrap: break-word; 
   word-break: break-all;
}


.under_line {
    height: 1px;
    width: 90%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 1.08%, rgba(255, 255, 255, 0.3) 52.9%, rgba(255, 255, 255, 0) 100%);
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}
.btn_cont_wrapper {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
}
.rounded_btn {
    border-radius: 30px;
    font-family: 'Montserrat';   
    font-style: bold; 
 }


 @media screen and (max-width: 750px) {
    .card {
        width: 85%; 
        margin-left: 7px;
        margin-left: 7px;       
    }  
}
@media screen and (max-width: 300px) {
    .card {
        margin-left: 3px;
        margin-left: 3px;       
    }  
    .btn_cont_wrapper {
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: space-around;
        width: 100%;
    }
    .btn_cont_wrapper :first-child :last-child {
       
        margin-bottom: 20px;
        
    }
    .hide_overflow {
    
    overflow-x: auto;
    
    }
}

 