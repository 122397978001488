.rot_box_wrapper {
    width: 100%;
    height: 50px;
    position: relative;    
}
.rot {
    border-radius: 50%;    
    position: absolute;
    left: 48%;
    top: 35%;   
}
.arr_dwn_crcl {
    position: absolute;
    right: -43px;
    top: 79%;
}
.arr_dwn_crcl span {
    cursor: pointer;
}
.arr_dwn_crcl span :hover {
    background-color: var(--wh-text);
    color: #071621d2;
    border-radius: 100%;
    outline: none;
    border: none;
}
    
    
    .rot span {
    -webkit-transform: rotate(6deg);
    -moz-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    -o-transform: rotate(6deg);
    transform: rotate(6deg);
    font: 7px Monaco, MonoSpace;
    height: 20px;
    position: absolute;
    width: 20px;
    left: 3px;
    top: 15;
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    -ms-transform-origin: bottom center;
    -o-transform-origin: bottom center;
    transform-origin: bottom center;
    
    }

    .rotate span {
        font: 10px Monaco, MonoSpace;
        height: 55px;
        position: absolute;
        width: 15px;
        left: 48%;
        top: 20px;
        transform-origin: bottom center;
    }
   
     :root {
    --a: 1deg;
    --b: calc(var(--a) + 90deg);
    --c: calc(var(--a) + 177deg);
    --d: calc(var(--a) + 270deg);
    } 
    
   
    .char1 { transform: rotate(calc(var(--a) - 36deg)); }
    .char2 { transform: rotate(calc(var(--a) - 30deg)); }
    .char3 { transform: rotate(calc(var(--a) - 24deg)); }
    .char4 { transform: rotate(calc(var(--a) - 18deg)); }
    .char5 { transform: rotate(calc(var(--a) - 12deg)); }
    .char6 { transform: rotate(calc(var(--a) - 6deg)); }
    .char7 { transform: rotate(calc(var(--a) + 0deg)); }
    .char8 { transform: rotate(calc(var(--a) + 6deg)); }
    .char9 { transform: rotate(calc(var(--a) + 12deg)); }
    .char10 { transform: rotate(calc(var(--a) + 18deg)); }
    .char11 { transform: rotate(calc(var(--a) + 24deg)); }
    .char12 { transform: rotate(calc(var(--a) + 30deg)); }
    .char13 { transform: rotate(calc(var(--a) + 36deg)); }

    .char21 { transform: rotate(calc(var(--b) - 36deg)); }
    .char22 { transform: rotate(calc(var(--b) - 30deg)); }
    .char23 { transform: rotate(calc(var(--b) - 24deg)); }
    .char24 { transform: rotate(calc(var(--b) - 18deg)); }
    .char25 { transform: rotate(calc(var(--b) - 12deg)); }
    .char26 { transform: rotate(calc(var(--b) - 6deg)); }
    .char27 { transform: rotate(calc(var(--b) + 0deg)); }
    .char28 { transform: rotate(calc(var(--b) + 6deg)); }
    .char29 { transform: rotate(calc(var(--b) + 12deg)); }
    .char210 { transform: rotate(calc(var(--b) + 18deg)); }
    .char211 { transform: rotate(calc(var(--b) + 24deg)); }
    .char212 { transform: rotate(calc(var(--b) + 30deg)); }
    .char213 { transform: rotate(calc(var(--b) + 36deg)); }

    .char31 { transform: rotate(calc(var(--c) - 36deg)); }
    .char32 { transform: rotate(calc(var(--c) - 30deg)); }
    .char33 { transform: rotate(calc(var(--c) - 24deg)); }
    .char34 { transform: rotate(calc(var(--c) - 18deg)); }
    .char35 { transform: rotate(calc(var(--c) - 12deg)); }
    .char36 { transform: rotate(calc(var(--c) - 6deg)); }
    .char37 { transform: rotate(calc(var(--c) + 0deg)); }
    .char38 { transform: rotate(calc(var(--c) + 6deg)); }
    .char39 { transform: rotate(calc(var(--c) + 12deg)); }
    .char310 { transform: rotate(calc(var(--c) + 18deg)); }
    .char311 { transform: rotate(calc(var(--c) + 24deg)); }
    .char312 { transform: rotate(calc(var(--c) + 30deg)); }
    .char313 { transform: rotate(calc(var(--c) + 36deg)); }
    .char314 { transform: rotate(calc(var(--c) + 42deg)); }

    .char41 { transform: rotate(calc(var(--d) - 36deg)); }
    .char42 { transform: rotate(calc(var(--d) - 30deg)); }
    .char43 { transform: rotate(calc(var(--d) - 24deg)); }
    .char44 { transform: rotate(calc(var(--d) - 18deg)); }
    .char45 { transform: rotate(calc(var(--d) - 12deg)); }
    .char46 { transform: rotate(calc(var(--d) - 6deg)); }
    .char47 { transform: rotate(calc(var(--d) + 0deg)); }
    .char48 { transform: rotate(calc(var(--d) + 6deg)); }
    .char49 { transform: rotate(calc(var(--d) + 12deg)); }
    .char410 { transform: rotate(calc(var(--d) + 18deg)); }
    .char411 { transform: rotate(calc(var(--d) + 24deg)); }
    .char412 { transform: rotate(calc(var(--d) + 30deg)); }
    .char413 { transform: rotate(calc(var(--d) + 36deg)); }
   
