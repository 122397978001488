.screen_four {
    margin-top: 30px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    position:relative;
}
.screen_four2 {
    margin-top: 30px;
    height: 90vh;
    display: flex;
    flex-direction: column;   
    justify-content: flex-start;
    position:relative;
}
.p_four_text_large {   
    color: '#FFFFFF';
    font-weight: 700;   
    font-size: var(--txt-bold);   
    line-height: var(--txt-bold-gap); 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.p_four_text_normal {
    margin-top: 1.5vw;   
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
    text-align: center;
}

.card_container {
  
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 30px;
    overflow-x: auto;
    
    white-space: nowrap; 
    z-index: 5;  
  }
  .no_car_container {    
      width: 100%;
      height: 100%;
  }
  .p4_img_car {
      z-index: 2;
  }
  /* .card_container {
  
    display: flex;
    flex-direction: row;
    align-items: left;
     
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;   
  } */
  .go_to_mp_btn {
    width: 250px;
    border-radius: 30px;
    font-family: 'Montserrat';   
    font-style: bold; 
}
.btn_container {
    width: 100%;
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btn_container :first-child {
    margin-right: 40px;
}
.card_container_utility_page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    justify-items: center ;  
}
.utilitypage_wrapper {
    position: relative;
}

  @media screen and (max-width: 1200px) {    
    .screen_four {
        margin-top: 30px;
        height: auto;
        display: block;
        margin-bottom: 30px;
       
    }
    .screen_four_bottom_img {
        display: none;
    }
    .p_four_text_normal {
        margin-top: 20px;
    }
    .card_container_utility_page {     
        grid-template-columns: 1fr 1fr;   
    }
}
@media screen and (max-width: 810px) {
    .card_container_utility_page {     
        grid-template-columns: 1fr;   
    }
}
@media screen and (max-width: 770px) {
.btn_container {
    flex-direction: column;
}
.btn_container :first-child {
    margin-right: 0;
    margin-bottom: 40px;
}
}
@media screen and (max-width: 380px) {
   
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
        margin-top: 30px;
    }
} 
/* @media screen and (max-width: 800px) {
    
}
@media screen and (max-width: 415px) and (min-width: 410px) {
    
   
} */



@media screen and (min-width: 1600px) {
    .screen_four {    
    height: 80vh;
}
}
