.rounded_btn {
   border-radius: 30px;
   font-family: 'Montserrat';   
   font-style: bold; 
}
.btn_cont_wrapper {
   display: flex; 
   flex-direction: row;   
   align-items:center;
   justify-content: center; 
   z-index: 100;
}
.btn_container {

}
.invis_text {
   color:rgba(245, 245, 220, 0);
}

/* Розділяємо послідовні класи відступом */
.btn_container + .btn_container {
   margin-left: 3vw;
}
.padding_txt {
   padding-left: 53px;
   padding-right: 53px;
}
.padding_txt2 {
   padding-left: 53px;
   padding-right: 53px;
}

@media screen and (max-width: 900px) {
   
   .darker_text {
       min-width: 100%;
   }
   .btn_cont_wrapper {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
   }
   .btn_container {
       margin-top: 15px;
       margin-bottom: 5px;
   }
   .btn_container + .btn_container {
      margin-left: 0vw;
      margin-top: 25px;
   }
   .padding_txt2 {
      padding-left: 27px;
      padding-right: 27px;
   }
   
}
/* @media screen and (max-width: 400px) {
   .padding_txt {
      padding-left: 33px;
      padding-right: 33px;
   }
} */