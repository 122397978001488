.modal__form {
    text-align:center;
    color:black;
}
.bg {
    background-color: var(--form-input-bg);
    color: var(--wh-text);
    outline: none;
    border: none;   
}
.txt_lg {
    color: var(--wh-text) !important;
    font-weight: 700;   
    font-size: var(--txt-bold);   
    line-height: var(--txt-bold-gap); 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}
.txt_normal {
    margin-top: 1.5vw;   
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
    text-align: center;
}
.rounded_btn {
    border-radius: 30px;
    font-family: 'Montserrat';   
    font-style: bold; 
 }
 .form_control {
    margin-top: 10px;
    background-color:var(--form-input-bg);
    border-radius: 20px;
    width: 80%;
    
}
.under_line {
    height: 1px;
    width: 90%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 1.08%, rgba(255, 255, 255, 0.3) 52.9%, rgba(255, 255, 255, 0) 100%);
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}
.btm_btn_container {   
 width: 100%;
 margin-top: 30px;
 display: flex;
 align-items: center;
 justify-content: flex-end;
}

.car_img {
    width: 20vw;
}
.car_details_container {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--wh-text);
    line-height: 30px;
}
.btn_text {
    display:block;
}
.btn_text_small {
    display: none;
}



@media screen and (max-width: 995px) {
    .txt_lg {
        font-weight: 500;
        font-size:calc(var(--txt-bold) - 5px);   
        line-height:calc(var(--txt-bold-gap) + 15px) ;
    }
    .btm_btn_container { 
        margin-top: 20px;
        flex-direction: column-reverse;
        gap: 20px;
        align-items: center;
        justify-content: flex-end;
       }
       .btn_text_small {
           display:block;
       }
       .btn_text {
           display: none;
       }

  }

  @media screen and (max-width: 540px) {
    .car_details_container {
        margin: 20px 0;
        flex-direction: column;
        line-height: 20px;
    }
    .car_img {
        margin: 0 0 15px 0 !important;
        width: 200px;
    }
    
  }

@media screen and (max-width: 370px) {
  .modal_footer_btn {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items:center;
    justify-content: center;
    gap: 20px;
  }
}

