.alert {
    font-size: large;
    color: var(--main-bg);
    font-weight: 500;
}
.alert_container {
    border-radius: 20px;
}

.select_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .ballast {
    width: 50%;
  }
  @media screen and (max-width: 770px) {
    .ballast {
        display: none;
      }
  }
