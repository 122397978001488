.ballast {
    height: 500px;
}
:root {
    --top_gap_one: 12vw;
}

.screen_one {
    background-color: var(--main-bg); 
    position:relative;   
    overflow: hidden; 
    height: 100vh; 
}
.theatre_div_bg {
    position:relative;
    top: -30px;
    right: -40px;  
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 90vh;
    width: 100vw;  
    z-index: -0;
}


.theatre_one {
    width: 100vw;
    position:absolute;
    top:  calc(var(--top_gap_one) - 11vw);
    margin-right: 5%;
    right: -1%;
    z-index: 0;
    border: none;
    outline: none;
    background: var(--main-bg);  
}
.theatre_two {
    width: 100vw;
    position:absolute;
    top:  calc(var(--top_gap_one) - 19vw);;
    right: 0;
    z-index: 0;
    border: none;
    outline: none;
    background: var(--main-bg); 
    display: none;
}

@media (max-width: 767px) {
    :root {
        --top_gap_one: 100vw;
    }
    .screen_one  {
        height: 100vh;
    }
    .theatre_one  {         
        display: none;        
    }
    .ballast {         
        display: none;        
    }
    .theatre_two {
        height: 95vh; 
        width: auto;     
        top:  -4%;
        right: -80%; 
        display: block; 
    }
   
    .screen_one {
        padding: 0px;
    }
    .circ_txt {
        position: absolute;
       
    }
    /* .theatre_div_bg {
        padding: 0px;
        background: url(../pic/p1-theatre.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 101vh;
    } */
}

.car_one_img {
    width: 58vw;
    position:absolute;
    right: -4%;
    top: var(--top_gap_one);
    z-index: 50;
    border: none !important;
    outline: none !important;
    border-style: none !important;
}
.plant_one_img {
    width: 37vw;
    position:absolute;
    right: 18%;
    top: calc(var(--top_gap_one) - 4vw);    
    z-index: 40;    
}
.plant2_one_img {
    width: 55vw;
    position:absolute;
    right: -20vw;
    top: calc(var(--top_gap_one) - 5vw);    
    z-index: 40;    
}
.bird1_one_img {
    width: 5.5vw;
    position:absolute;
    right: 23vw;
    top: calc(var(--top_gap_one) - 2.5vw);    
    z-index: 40;
}
.bird2_one_img {
    width: 4.5vw;
    position:absolute;
    right: 16vw;
    top: calc(var(--top_gap_one) - 6vw);    
    z-index: 40;

}

.circuls_one_img {
    width: 63vw;
    height: 63vw;
    position:absolute;
    right: 3vw;
    top: calc(var(--top_gap_one) - 11vw);    
    z-index: 10; 
    
}

.p_one_text_container {
    position: absolute;
    width: 45vw;
    top: calc(var(--top_gap_one) - 2vw); 
    left: 14vw;
    z-index: 50;
}

.sm_screen_gap {
    display: none;
}



.p_one_text_large {
  color: '#FFFFFF';
  font-weight: 900;   
  font-size: var(--txt-black);   
  line-height: var(--txt-black-gap);
  text-transform: uppercase;
display: flex;
align-items: center;
justify-content: flex-start;
text-align: left;
   
}



.p_one_text_normal {
    margin-top: 2vw;   
    font-weight: 300;
    font-size: 22px;
    line-height: 35px;
    text-align: left;
}
.p_one_btn {
    margin-top: 3vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.circ_txt {
    position: absolute;   
    top: calc(var(--top_gap_one) + 28.5vw); 
    right: 50%;
    top: 80vh;
    z-index: 50;
}

@media (max-width: 1050px) {
    .p_one_text_container {
        width: 70vw;
        top: 120px; 
        left: 30px;
    }
    .p_one_text_large {
        font-weight: 700; 
    }
}


@media (max-width: 767px) {  
    .circ_txt {
        position: absolute;
        top: 80vh;
    }
    .car_one_img {
        width: 600px !important;       
        right: -20%;
        top:60%;
        bottom: 0 !important;
    }
   
}

@media (max-width: 600px) {
    .p_one_text_container {
        top: 80px; 
        left: 0px;
    }
    .p_one_text_large {
        font-weight: 500; 
        font-size: var(--txt-bold); 
    }
}

@media (max-width: 415px) {
    .sm_screen_gap {
        display: block;
        height: 80px;
      }
}
@media (max-width: 391px) {  
    .p_one_text_container {
        top: 40px; 
        left: -10px;
    }
    .p_one_text_large {
        font-weight: 500; 
        font-size: var(--txt-bold); 
        line-height:calc( var(--txt-black-gap) - 10px);
    }
    
}
@media (max-width: 376px) {
    .p_one_text_normal {    
    font-size: 16px;
    line-height: 23px;    
  }
  
}
@media (max-width: 290px) {
    .p_one_text_large {         
        font-size:calc(var(--txt-bold) - 2px) ; 
        line-height:calc( var(--txt-black-gap) - 13px);
    }
    .p_one_text_normal {    
    font-size: 16px;
    line-height: 23px;    
  }
  .sm_screen_gap {      
    height: 65px;
  }
  
 
}
