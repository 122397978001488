.screen_three {
    height: 50vh;
    display: flex;
    align-items:center;
    justify-content: center;
    overflow: hidden;
}
.screen_three_bg_img {
    height: 48vh;
    width: 60vw;
    margin-left: 15vw;
    background: url(../../pic/p3_bg.png);    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 2vh;
    border-radius: 30px;
   position: relative;
}
.screen_three_text_box {
    padding-top: 10%;
    padding-left: 7%;
}
.p_three_text_large {
    color: '#FFFFFF';
  font-weight: 700;   
  font-size: var(--txt-bold);   
  line-height: var(--txt-bold-gap); 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.p_three_text_normal {
    margin-top: 1.5vw;   
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
    text-align: left;
}
.p3_img_car {
    width: 32vw;
    position: absolute;
    right: 0;
    bottom: 0;
}
.btn_wrapper {
    padding-top: 1.5vw;
    display: flex;
    align-items:left;
}

@media screen and (max-width: 900px) {
   
    .screen_three_bg_img {
        width: 98vw;
        margin-right: 1vw;
        margin-left: 1vw;
        background-position: right;
        border-radius: 0;
    }
    .p3_img_car {
        width: 50vw;       
    }
} 
@media screen and (max-width: 730px) { 
    .p3_img_car {
        width: 400px;
       
    }
} 
@media screen and (max-width: 550px) { 
    .p3_img_car {
        right: -25%;
        overflow: hidden;
    }
} 
@media screen and (max-width: 380px) {
   
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
        margin-top: 30px;
    }
    
    .p_three_text_large {
      
      align-items: flex-start;
      margin-top: 0;
    }
    .btn_wrapper {
        width: 100%;
        padding-top: 1.5vw;       
        align-items:center;
        padding-right: 0;
        margin-right: 0;
    }
    .p3_img_car {
        right: -45%;
       
    }
    

} 
