.screen_two {
    /* height: 103vh; */
}
.screen_two_thankyou {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.go_to_mp_btn {
    width: 250px;
    border-radius: 30px;
    font-family: 'Montserrat';   
    font-style: bold; 
}
.screen_two_text_box_thankyou {
    width: 55vw;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    
    padding-left: 4vw;
}

.screen_two_page {
    margin-top: 5vh;
    padding-top: 5vh;
    height: 50vh;
    display: flex;
    flex-direction: row;
}
.screen_two_part_two {
    padding-top: 5vh;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}


.screen_two_img_box {
    position: relative;
    width: 45vw;
   
}
.screen_two_text_box {
   width: 55vw;
   height: 100%;
   display:flex;
   flex-direction: column;
   justify-content: center;
   padding-left: 4vw;
}

.p_two_text_large {
    color: '#FFFFFF';
  font-weight: 700;   
  font-size: var(--txt-bold);   
  line-height: var(--txt-bold-gap); 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.p_two_text_normal {
    margin-top: 3vw;   
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
    text-align: left;
}

.p2_img {
    height: 45vh;
}



.three_img_box_item_one {
    height: 100%;
    background: url(../../pic/p2_imgroup2_1.svg);
    
}
.three_img_box_item_two {
    height: 117%;
    background: url(../../pic/p2_imgroup2_2.svg);
    margin-left: 2vw;
}
.three_img_box_item_three {
    height: 100%;
    background: url(../../pic/p2_imgroup2_3.svg);
}
.three_img_box_item {
    width: 25vw;    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
}

.screen_two_part_two_img_box {
    height: 35vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:flex-start;
    overflow: hidden; 
    width: 90%;  
}
.item3 {
    width: 29%;
    max-width: 330px;
    flex-grow: 1;
}
.item1 {
    width: 32%;
    max-width: 330px;
    flex-grow: 1;
}
.item2 {
    width: 29%;
    max-width: 330px;
    flex-grow: 1;
}
@media screen and (min-width: 1300px) {
    .screen_two_part_two_img_box {
        justify-content: center;
    }
    .item1 {
        margin: 0 40px;
    }
}

@media screen and (max-width: 850px) {
    .p2_show_on_lg_scr {
        display: none;
    }
    .biger_line_h {
        padding-top: 0;
        margin: 0;
    }
    
    .screen_two_page {
        margin-top: 0;
        padding-top: 10px;
        flex-direction: column;
        align-items:center;
        justify-content: flex-start;
        height: 90vh;
    }
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
        margin-top: 30px;
    }

    
    .screen_two_img_box {
        width: 100%;
    }
    
    .p2_img {
        
        margin-top: 20px; 
        padding-right: 40px;
      
       
      }
      .screen_two_text_box {
          width: 100%;        
          padding-top: 0;
          margin: 0 0 0 10px;
          align-items: flex-start;
          justify-content: flex-start;
      }
      .screen_two_page {
          padding-bottom: 0 !important;
      }
      .screen_two_text_box {
        padding-bottom: 0;
      }
    
}


@media screen and (max-width: 740px) {
    .screen_two_img_box {
        padding-top: 0;
        margin-top: 0;
    }
    .screen_two_text_box {
        padding-top: 0;
        margin-top: 0;
    }
    .screen_two_part_two {
        padding-top: 20px;
        padding-bottom: 0 !important;
        height: 980px;
        justify-content: center; 
        align-items:center; 
      }
    .screen_two_part_two_img_box {
        height: 100%;  
        width: 100%;     
        flex-direction: column;
        justify-content: flex-start; 
        align-items:center;        
    }
    .item3 {  
        width: 280px; 
        flex-grow: 0 ;
    }
    .item1 { 
        max-width: 400px; 
        width: 400px; 
        margin-top: 30px; 
        flex-grow: 0 ;
    }
    .item2 { 
        max-width: 400px; 
        width: 350px; 
        flex-grow: 0; 
        padding-right: 30px;
    }
    .screen_two_text_box_thankyou {
        padding-left: 0;
        align-items: center;
    }
   
}
@media screen and (max-width: 351px) {
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
        margin-top: 0px;
    }
}
@media screen and (max-width: 281px) {
   
    .p_two_text_large {       
      font-weight: 500;   
      font-size: calc(var(--txt-bold) - 2px);   
      line-height: calc(var(--txt-bold-gap) + 12px) !important;      
      justify-content: flex-start;
      text-align: center;
    }
    
    .p_two_text_normal {
        margin-top: 3vw;   
        font-weight: 300;
        font-size: var(--txt_nomal);
        line-height: 25px;
        text-align: left;
    }
}