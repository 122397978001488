@keyframes globe {
    0% {opacity: 1; 
        transform: scale(-1.2, 1.2);
        /* transform: translateY(30%); */
    }   
    100% {opacity: 0.5;
        transform: scale(1, 1);
        /* transform: translateY(50%); */
    }
}


.globe_rotate {
    width: 80%;
    margin-left: 10%;     
    animation: globe 0.8s infinite alternate;
  }
  .green_color {
       color: var(--wh-text);
  }

  .spinner_background {
      /* background-color:antiquewhite; */
      /* background: url(../pic/bg.jpg) no-repeat fixed bottom; */
      /* background: url(../pic/bg.jpg) no-repeat;
      background-size: cover; */
      height: 80vh;
      margin-top: 3vh;
      padding-bottom: 3vh;
     
  }