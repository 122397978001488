:root {  
  --wh-text: #ffffff;
  --dark-shade-bg: rgba(1, 1, 1, 0.249);
  --light-shade-bg: rgba(255, 255, 255, 0.1);
  --darker-text: rgba(30, 28, 28, 1);
  --contrast_txt: #15B1ED;
  --light-bg: rgba(221, 219, 232, 0.632);
  --border-color: #777;
  --border-radius: 10px;
  --main-bg: #071621;
  --txt-black: calc(32px + (12 + 12 * 0.7) * ((100vw - 320px) / 1280));
  --txt-black-gap: calc(45px + (14 + 14 * 0.7) * ((100vw - 320px) / 1280));
  --txt-bold: calc(24px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  --txt-bold-gap: calc(10px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  --gap-big-txt-sm-screen: 40px;
  --txt-normal: calc(14px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  --form-bg:#041726CC; 
  --form-input-bg:#041726CC;
  /* --a: 0deg;
    --b: 90deg;
    --c: 180deg;
    --d: 270deg; */
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'Montserrat', sans-serif;  
  font-style: normal;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
  padding-bottom: 0;
  background-color: var(--main-bg);
  
}

*::-webkit-scrollbar {
  width: 0.7em;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--main-bg);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  outline: 1px solid slategrey;
  border-radius: 10px;
  width: 100px;
 
}

h1, h2, h3, h4, h5, h6 {
  color: '#FFFFFF';
}



h3 {
  color: '#FFFFFF';
  font-weight: 700;
  font-size: var(--txt-bold);
  line-height: var(--txt-bold-gap);
 
}


h4 {
  font-size: calc(16px + 8 * (100vw / 1280));
}
h5 {
  font-size: calc(14px + 8 * (100vw / 1280));
}
.adaptiv_text_one {
  font-size: calc(10px + 6 * (100vw / 1280));
}
@media (max-width: 767px) {
  h4 {
    font-size: calc(16px + (8 + 8 * 0.7) * ((100vw - 320px) / 1280));
  }
  h3 {
    font-size: calc(18px + (9 + 9 * 0.7) * ((100vw - 320px) / 1280));
  }
  h5 {
    font-size: calc(14px + (8 + 8 * 0.7) * ((100vw - 320px) / 1280));
  }
  .adaptiv_text_one {
    font-size: calc(10px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  }
}
