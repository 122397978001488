


.captcha_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color:bisque; */
    align-items: center;
    justify-content: space-between;
}
.captcha_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color:bisque; */
    align-items: center;
    justify-content: space-between;
}
/* 'ml-md-3 mt-3 mt-md-0' */
.input_container {
 margin-left: 2rem;

}
@media screen and (max-width: 540px) {
    .captcha_wrapper {
        flex-direction: column;
    }
    .input_container {
        margin-top: 2rem;
        margin-left: 0;
    }
}
.captcha_img_box {
    position: relative;    
    width: 195px;
   
}
.captcha_txt {
    position:absolute;
    top:0;
    left: 0;
    height: 100%;   
    display: flex;
    flex-direction: column;
   cursor: none;
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none;   /* Chrome/Safari/Opera */
   -khtml-user-select: none;    /* Konqueror */
   -moz-user-select: none;      /* Firefox */
   -ms-user-select: none;       /* Internet Explorer/Edge */
   user-select: none;
   font-size: xx-large;
   font-weight: 500;
   letter-spacing: 5px;
   transform: rotate(25deg);
   filter:blur(1px);
   color:blueviolet;
   font: MonoSpace;
  
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items:center;
   justify-content: center;
}


.go_btn {
    
     height:  40px;
     padding: 0;    
     border-radius: 25px;   
     width:  80px;   
     font-size:  14px;
}
.renew_btn {
    height:  40px;
    padding: 0;
    border-radius: 25px;
    width:  100%;
    font-size:  14px;
}
.value_input {
     height:  40px;
     padding: 0;    
     border-radius: 5px;   
     width: 250px; 
     color: var(--wh-text);
     padding-left: 10px;
     
     
     background-color:var(--form-bg);
     border-radius: 20px;
}

.under_line {
    height: 1px;
    width: 90%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 1.08%, rgba(255, 255, 255, 0.3) 52.9%, rgba(255, 255, 255, 0) 100%);
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}

@media screen and (max-width: 281px) {
    .renew_btn {       
        width:  100%;
        font-size:  12px;
    }
    .value_input {
         width: 220px;
         font-size:  12px;
    }
}







/* .go_btn {
    background:  transparent;
     height:  38px;
     line-height:  40px;
     border:  2px solid white;
     border-radius: 25px;
     display:  inline-block;
     float:  none;
     text-align:  center;
     width:  120px;
     padding: none;
     font-size:  14px;
     color:  #fff;
     cursor:pointer;
     outline:none;
     overflow: hidden;
     align-items: center;
}
.go_btn :hover  {
    color:  #fff;
    background:  rgba(255, 255, 255, 0.2);
} */