.screen_six {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    position: relative;
}

.p_six_text_large { 
    
    width: 100%; 
    color: '#FFFFFF';
    font-weight: 700;   
    font-size: var(--txt-bold);   
    line-height: var(--txt-bold-gap); 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}



.inner_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 3vw;
    z-index: 2;
}

.screen_six_bg_img_container {
    width: 100%;
    margin-top: 15vh;
    margin-bottom: 15vh;
    height: 40vh;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
}

.screen_six_center_txt {
    width: 100%;
    margin: 10px auto 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
}
.screen_six_txt_box {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
}

.screen_four_bottom_img {
    width: 20vw;
    position: absolute;
    left: 0;
    bottom: 0vh;
    z-index: 1;   
}

@media screen and (max-width: 800px) {
    .screen_six {
        height: 160vh;
        align-items:flex-start;
        justify-content: flex-start;
    }
    .screen_six_bg_img_container {
        margin: 10px 0 40px 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items:center;
    }
  
    .p_six_text_large { 
        margin-top: 40px;
        align-items: flex-start;
        justify-content: center;
        text-align: flex-start;
        z-index: 1;
    }
    .inner_col {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .screen_six_txt_box {
        margin-bottom: 20px;
       
    }
    .screen_six_img_sub_container {
        flex-direction: column;
    }
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
    }
    .screen_four_bottom_img {
        width: 150px;         
    }
    
}
@media screen and (max-width: 380px) {
    .screen_six {
        height: 180vh;       
    }
    .biger_line_h {
        line-height: var(--gap-big-txt-sm-screen) !important;
        margin-top: 30px;
    }
} 
@media screen and (max-width: 415px) and (min-width: 410px) {
    .screen_six {
        height: 135vh;             
    }
    .screen_four_bottom_img {
        bottom: -70px;         
     }
   
}
@media screen and (max-width: 281px) {
    .screen_four_bottom_img {
       bottom: -90px;         
    }
} 
