.car_details_container {
   color: var(--wh-text) !important;
}
.car_img {
    margin-top: 20px;
    height: 45vh;
    border-radius:20px;
}
.cd_carousel {
    height: 50vh;
}
.cd_txt {
    margin-top: 1.5vw;   
    font-weight: 500;
    font-size: var(--txt_bold);
    line-height: 25px;
    text-align: center;
}
@media screen and (max-width: 540px) {
    .car_img {
        height: 200px;
    } 
    .cd_carousel {
        height: 250px;
    }
}
@media screen and (max-width: 370px) {
    .car_img {
        height: 150px;
    } 
    .cd_carousel {
        height: 200px;
    }
}