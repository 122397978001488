.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    font-weight: 300;
    font-size: var(--txt_nomal);
    line-height: 25px;
    margin: 15vh 0;
}

.contrast_txt {
    color: var(--contrast_txt);
    font-weight: 700;
    font-size: var(--txt_bold);
}

