.screen {
    background-color: var(--main-bg);    
}
.viber_icon {
    font-size: 36px;
}

.icons_container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icons_container a {  
    color: var(--wh-text);
    
}